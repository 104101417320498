import React, { useState } from "react"
import { addToObject } from "../assets/themes/default"
import { graphql } from "gatsby"
import CloudConvert from "cloudconvert"
import InputText from "../components/shared/InputText"
import Layout from "../components/layout"
import ResponsiveTableWrapper from "../components/shared/ResponsiveTableWrapper"
import Section from "../components/shared/Section"
import StyledLink from "../components/shared/StyledLink"
import Text from "../components/shared/Text"

const CaseStudiesPDFGenerator = ({ data }) => {
  var caseStudies = ""
  const [jobStatus, setJobStatus] = useState("")
  const [downloadLink, setDownloadLink] = useState("")
  const cloudConvert = new CloudConvert(process.env.GATSBY_CLOUDCONVERT_TOKEN)
  const baseUrl = `${process.env.GATSBY_CURRENT_DOMAIN}/downloads/case-studies`
  const frontPageUrl = `${process.env.GATSBY_CURRENT_DOMAIN}/pdf/case-studies-front-page.pdf`

  const inputPDF = []
  data.allContentfulCaseStudy.edges.map(({ node: caseStudy }, index) =>
    inputPDF.push({
      id: index,
      companyName: caseStudy.companyName,
      title: caseStudy.title,
      url: `${baseUrl}/${caseStudy.slug}.pdf`,
    })
  )

  const buttonHandler = () => {
    setJobStatus("created")
    setDownloadLink("")

    let documents = caseStudies.split(",")
    if (documents.length === 0) {
      setJobStatus("invalid-ids")
      return false
    }

    let cloudConvertTasks = {}
    let keyName = ""
    let importTasks = []
    documents.forEach((document_id, index) => {
      if (document_id >= 0 && document_id < inputPDF.length) {
        keyName = `import-${index + 1}`
        cloudConvertTasks = addToObject(cloudConvertTasks, keyName, {
          operation: "import/url",
          url: inputPDF[document_id].url,
        })
        importTasks.push(keyName)
      } else {
        setJobStatus("invalid-ids")
      }
    })

    if (importTasks.length === 0) {
      setJobStatus("invalid-ids")
    }

    if (jobStatus === "invalid-ids") {
      return false
    }

    // Add front page
    keyName = "import-0"
    cloudConvertTasks = addToObject(cloudConvertTasks, keyName, {
      operation: "import/url",
      url: frontPageUrl,
    })
    importTasks.unshift(keyName)

    cloudConvertTasks = addToObject(cloudConvertTasks, "task-merge", {
      operation: "merge",
      output_format: "pdf",
      engine: "poppler",
      input: importTasks,
    })

    cloudConvertTasks = addToObject(cloudConvertTasks, "task-export", {
      operation: "export/url",
      input: "task-merge",
      inline: false,
      archive_multiple_files: true,
    })
      ; (async () => {
        clearCaseStudies()

        const job = await cloudConvert.jobs.create({
          tasks: cloudConvertTasks,
        })

        cloudConvert.jobs.subscribeEvent(job.id, "finished", (event) => {
          setJobStatus("finished")
          setDownloadLink(event.job.tasks[0].result.files[0].url)
          cloudConvert.socket.close()
        })

        cloudConvert.jobs.subscribeEvent(job.id, "failed", (event) => {
          console.log(event)
          setJobStatus("failed")
          cloudConvert.socket.close()
        })
      })()
  }

  const clearCaseStudies = () => {
    caseStudies = ""
    document.getElementById("caseStudies").value = ""
  }

  return (
    <Layout>
      <Section>
        <ResponsiveTableWrapper>
          <Text type="subtitle">Available Case Studies</Text>
          <table>
            <thead>
              <tr key="head">
                <th>ID</th>
                <th>Company</th>
                <th>Title</th>
              </tr>
            </thead>
            <tbody>
              {inputPDF.map((document) => (
                <tr key={document.id}>
                  <td>{document.id}</td>
                  <td>{document.companyName}</td>
                  <td>{document.title}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <InputText
            isRequired
            label="Your selection"
            helpText="Example: 0,1,2,3"
            name="caseStudies"
            onChange={(e) => caseStudies = e.target.value}
            placeholder="IDs"
          />
          {jobStatus === "" && (
            <StyledLink isPrimary onClick={buttonHandler} to={`#`}>
              Make PDF
            </StyledLink>
          )}
          {jobStatus === "invalid-ids" && (
            <>
              <Text>The IDs are invalid. Try again.</Text>
              <StyledLink isPrimary onClick={buttonHandler} to={`#`}>
                Make PDF
              </StyledLink>
            </>
          )}
          {jobStatus === "created" && <Text>Job added. Please wait...</Text>}
          {jobStatus === "failed" && (
            <Text>Job failed. Please try again later.</Text>
          )}
          {jobStatus === "finished" && downloadLink && (
            <StyledLink to={downloadLink} isPrimary isExternal>
              Download PDF
            </StyledLink>
          )}
        </ResponsiveTableWrapper>
      </Section>
    </Layout>
  )
}

export default CaseStudiesPDFGenerator

export const pageQuery = graphql`
  query caseStudiesAvailableQuery {
    allContentfulCaseStudy(
      sort: { fields: createdAt, order: DESC }
      filter: { slug: { ne: "dummy-content" } }
    ) {
      edges {
        node {
          id
          title
          companyName
          slug
          createdAt
        }
      }
    }
  }
`
